<i18n>
ru:
  averageTimeWarning: Ориентировочное время выполнения заказа
  deliveryTime: Время доставки
  edit: Изменить
  optionASAP: Как можно скорее
  optionScheduled: Ко времени
  orderPickupTime: Время получения заказа
  timeSelection: Выбор времени
ua:
  averageTimeWarning: Орієнтовний час виконання замовлення
  deliveryTime: Час доставки
  edit: Редагувати
  optionASAP: На найближчий час
  optionScheduled: На вказаний час
  orderPickupTime: Час отримання замовлення
  timeSelection: Вибір часу
us:
  averageTimeWarning: Estimated order delivery time
  deliveryTime: Delivery time
  edit: Edit
  optionASAP: As soon as possible
  optionScheduled: Delivery to
  orderPickupTime: Order pickup time
  timeSelection: Time selection
</i18n>

<template>
  <div
    class="v-delivery-time v-d-flex v-flex-column"
    v-if="clientStore.ClientState.data"
  >
    <div
      class="v-typography-title-3 v-mb-lg"
      v-html="translate('deliveryTimePage.deliveryTime')"
    />
    <div class="v-delivery-time__options v-mb-sm">
      <ui-option-slider
        :label="translate('deliveryTime.deliveryTime')"
        :options="[true, false]"
        :is-item-disabled="isItemDisabled"
        v-model:selected="rightNow"
      >
        <template #option="option">
          <template v-if="option">
            <div
              v-if="option.value"
              data-test-id="delivery-quicker"
              v-html="translate('deliveryTimePage.optionASAP')"
            />
            <div
              v-else
              data-test-id="delivery-scheduled"
              v-html="translate('deliveryTimePage.optionScheduled')"
            />
          </template>
        </template>
      </ui-option-slider>
    </div>

    <transition
      mode="out-in"
      name="slidedown"
    >
      <template v-if="!rightNow">
        <cart-delivery-time-rath
          v-if="
            appConfig.VueSettingsPreRun.DeliveryTimeLayout === 'RathCelerdain' ||
            appConfig.VueSettingsPreRun.DeliveryTimeLayout === 'RathDinen'
          "
        />
        <cart-delivery-time-rathloriel
          v-else-if="appConfig.VueSettingsPreRun.DeliveryTimeLayout === 'Rathloriel'"
        />
      </template>
    </transition>
    <div
      class="v-delivery-time__average-time"
      v-if="
        appConfig.VueSettingsPreRun.AverageTimeDisplayInCart &&
        clientStore.averageTimeWithDelayMs > 0
      "
    >
      <span
        class="v-mr-xs"
        v-html="translate('deliveryTimePage.averageTimeWarning')"
      />
      <header-parts-average-time-base is-address-selector-popup-time />
    </div>
  </div>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()

import { DateTime } from 'luxon'
const { translate } = useI18nSanitized()
const clientStore = useClientStore()

const updateRestrictionsIntervalId = ref<ReturnType<typeof setTimeout> | null>(null)

const rightNow = computed<boolean>({
  get() {
    return clientStore.ClientState?.data?.StateOrderData?.DeliverRightNow ?? true
  },
  set(value) {
    clientStore.updateOrderData({
      deliverRightNow: value,
      refreshState: true
    })
  }
})

onMounted(() => {
  updateRestrictionsIntervalId.value = setInterval(
    () => {
      if (
        (clientStore.ClientState.data?.StateOrderData?.DeliveryTime ?? 0) * 1000 <=
        DateTime.utc().toMillis()
      )
        clientStore.updateOrderData({
          deliveryTime: 0,
          refreshState: true
        })
    },
    5 * 60 * 1000
  )
})
onUnmounted(() => {
  if (updateRestrictionsIntervalId.value) {
    clearTimeout(updateRestrictionsIntervalId.value)
    updateRestrictionsIntervalId.value = null
  }
})

function isItemDisabled(value: boolean): boolean {
  if (
    !clientStore.ClientState.data ||
    !clientStore.ClientState.data.LimitOrderPeriod
  )
    return true

  if (value)
    return (
      !clientStore.ClientState.data.LimitOrderPeriod.ASAP
        .EnabledByTerminalSettings ||
      !clientStore.ClientState.data.LimitOrderPeriod.ASAP.AvailableNow
    )

  return (
    !clientStore.ClientState.data.LimitOrderPeriod.Scheduled
      .EnabledByTerminalSettings ||
    Object.keys(
      clientStore.ClientState.data?.LimitOrderPeriod?.Scheduled?.Schedule?.Dates ??
        []
    ).length === 0
  )
}

watch(
  () => clientStore.ClientState.data?.Version ?? null,
  (newContent: number | null, oldContent: number | null) => {
    if (oldContent === null) return // after initialization oldContent is null
    if (newContent === null) return // in case of client state update error

    if (
      oldContent !== newContent &&
      clientStore.ClientState?.data?.StateOrderData &&
      !clientStore.ClientState.data.StateOrderData.DeliverRightNow &&
      clientStore.ClientState?.data?.StateOrderData.DeliveryTime <= 0
    ) {
      clientStore.updateOrderData({
        deliverRightNow: true,
        refreshState: true
      })
    }
  }
)
</script>

<style lang="scss">
@use 'assets/mixins';
@use 'assets/variables';

.v-delivery-time {
  &__options {
    max-width: 30rem;
  }
}

.v-delivery-time-buttons-popup {
  .v-delivery-time-buttons-wrapper {
    max-height: 60vh;
    overflow-y: auto;
  }

  .v-delivery-time-single {
    @include mixins.trans;

    padding: 0.715rem 2.645rem;
    cursor: pointer;
    font-size: variables.$TextSizeMain;
    font-variant-numeric: tabular-nums;
    border: 1px solid variables.$Mono400;
    border-radius: variables.$BorderRadiusButton;
    text-align: center;

    &:hover {
      border: 1px solid variables.$SecondaryBackgroundColor;
      color: variables.$SecondaryBackgroundColor;
    }

    &--selected {
      border: 1px solid variables.$SecondaryBackgroundColor;
      background: variables.$SecondaryBackgroundColor;
      color: variables.$SecondaryColor;

      &:hover {
        background: variables.$SecondaryBackgroundColorHover;
        color: variables.$SecondaryColor;
      }
    }
  }
}
</style>
