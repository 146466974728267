<template>
  <transition
    appear
    mode="out-in"
    name="fade"
  >
    <div
      v-if="minSumNotReached"
      class="v-min-delivery-sum v-mb-sm"
      data-test-id="min-delivery-sum-text"
    >
      <span v-html="translate('cartCommon.minDeliverySum')" />
      <common-currency
        :amount="minSumForDelivery ?? 0"
        data-test-id="min-delivery-sum"
      />
    </div>
  </transition>

  <common-skeleton v-if="orderButtonBlocked" />
  <ui-button
    :disabled="buttonDisabled"
    data-test-id="submit-order-button"
    :click-event="showMessagesAndSendOrder"
    :label="translate('cartCommon.submit')"
    color="primary"
    type="fill"
    width="wide"
    :size="48"
  />
</template>

<script setup lang="ts">
import type { CreateOrderButton } from '~types/props'

const { disabled } = defineProps<CreateOrderButton>()

const { translate } = useI18nSanitized()

const clientStore = useClientStore()

const { minSumNotReached } = useAddressSelector()
const { pathname } = useUrl()
const appConfig = useAppConfig()

const orderButtonBlocked = ref<boolean>(false)

const minSumForDelivery = computed<number>(
  () => clientStore.ClientState.data?.DeliveryPaymentInfo?.ModifiedMinSum ?? 0
)

const buttonDisabled = computed<boolean>(() => {
  if (disabled) return true

  if (
    appConfig.RestaurantSettingsPreRun.OneStageCart ||
    pathname === appConfig.VueSettingsPreRun.Links.CartSecondStep
  ) {
    if (clientStore.ClientState?.data?.StateOrderData?.DeliverRightNow ?? false) {
      if (
        !(
          clientStore.ClientState?.data?.LimitOrderPeriod?.ASAP
            ?.EnabledByTerminalSettings ?? true
        ) ||
        !clientStore.ClientState?.data?.LimitOrderPeriod?.ASAP?.AvailableNow
      ) {
        return true
      }
    } else if (
      !(
        clientStore.ClientState?.data?.LimitOrderPeriod?.Scheduled
          ?.EnabledByTerminalSettings ?? true
      )
    ) {
      return true
    }

    if (appConfig.RestaurantSettingsPreRun.AllowDisableCallback) {
      //callback check
      return (
        clientStore.ClientState.data?.UserOrderedCallback !== true &&
        clientStore.ClientState.data?.UserOrderedCallback !== false
      )
    }
  }

  return orderButtonBlocked.value
})
</script>

<style lang="scss">
@use 'assets/variables';

.v-label-check-privacy {
  margin: 0;
  padding: 0;

  .v-checkbox {
    flex: 0 0 20px;
    max-width: 20px;
  }

  .v-checkbox-text {
    flex: 0 0 90%;
    max-width: 90%;
    margin-left: 15px;
  }
}

.v-submit-button {
  width: 100%;

  .v-currency-wrapper {
    font-weight: 600;
  }
}

.v-min-delivery-sum {
  display: flex;
  align-items: flex-end;
  font-size: variables.$TextSizeMain;
  gap: 3px;
}

a {
  color: variables.$LinkColor;
}
</style>
