<template>
  <ui-button
    :disabled="buttonDisabled"
    :label="translate('sidebarContent.nextStepButton')"
    color="primary"
    type="fill"
    width="wide"
    :size="48"
    :click-event="showMessagesAndSendOrder"
  >
    <i18n-t
      keypath="secondStepPage.checkout"
      scope="global"
    >
      <template #amount>
        <common-currency
          class="v-ml-xs"
          :amount="clientStore.ClientState.data?.FinalCalculatePrice ?? 0"
        />
      </template>
    </i18n-t>
  </ui-button>
</template>

<script setup lang="ts">
import type { CreateOrderButton } from '~types/props'

const { disabled } = defineProps<CreateOrderButton>()

const clientStore = useClientStore()

const { translate } = useI18nSanitized()

const buttonDisabled = computed<boolean>(() => {
  if (disabled) return true

  if (clientStore.ClientState?.data?.StateOrderData?.DeliverRightNow ?? false) {
    if (
      !(
        clientStore.ClientState?.data?.LimitOrderPeriod?.ASAP
          ?.EnabledByTerminalSettings ?? true
      ) ||
      !clientStore.ClientState?.data?.LimitOrderPeriod?.ASAP?.AvailableNow
    ) {
      return true
    }
  } else if (
    !(
      clientStore.ClientState?.data?.LimitOrderPeriod?.Scheduled
        ?.EnabledByTerminalSettings ?? true
    )
  ) {
    return true
  }

  return false
})
</script>
