<template>
  <div class="v-d-flex v-justify-content-between">
    <div class="v-typography-title-6">
      <span
        v-if="timeString"
        v-html="timeString"
      />
    </div>
    <ui-button
      v-if="
        clientStore.ClientState.data?.LimitOrderPeriod?.Scheduled
          ?.EnabledByTerminalSettings
      "
      :size="14"
      color="secondary"
      type="text-with-button"
      width="content"
      :label="translate('deliveryTimePage.edit')"
      :click-event="callForPopup"
    />
  </div>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()

const { translate } = useI18nSanitized()
const clientStore = useClientStore()
const timeString = ref<string | null>(null)
const { fromMillisInZone } = useDateTime()
const popupStore = usePopupStore()

async function callForPopup(): Promise<void> {
  await popupStore.openPopup({
    popupClosable: true,
    popupName:
      appConfig.VueSettingsPreRun.DeliveryTimeLayout === 'RathCelerdain'
        ? 'deliveryTimeRathCelerdainPopup'
        : 'deliveryTimeRathDinenPopup'
  })
}

function refreshFields(): void {
  const time: number | undefined =
    clientStore.ClientState?.data?.StateOrderData?.DeliveryTime

  if (time) {
    const dt = fromMillisInZone(time * 1000, appConfig.RestaurantSettingsPreRun.GMT)

    let period = `${dt.hour}:${dt.minute}`

    if (clientStore.ClientState.data?.LimitOrderPeriod?.Scheduled?.ByPeriods) {
      const dateValues = Object.values(
        clientStore.ClientState.data?.LimitOrderPeriod?.Scheduled?.Schedule?.Dates ??
          []
      )

      if (dateValues.length > 0) {
        const selected = dateValues
          .flatMap((date) => Object.entries(date.Periods))
          .find(([_key, date]) => date.Date === time)

        if (selected) {
          period = selected[0]
        }
      }
    }

    timeString.value = `${dt.toLocaleString({
      day: 'numeric',
      month: 'short',
      weekday: 'short'
    })}  ${period}`
  } else {
    timeString.value = null
  }
}

onMounted(refreshFields)

watch(
  () => clientStore.ClientState.data?.Version ?? null,
  (newContent: number | null, oldContent: number | null) => {
    if (oldContent === null) return // after initialization oldContent is null
    if (newContent === null) return // in case of client state update error

    if (oldContent !== newContent) refreshFields()

    if (
      !(clientStore.ClientState?.data?.StateOrderData?.DeliverRightNow ?? true) &&
      (clientStore.ClientState?.data?.StateOrderData?.DeliveryTime ?? 0) === 0 &&
      Object.keys(
        clientStore.ClientState.data?.LimitOrderPeriod?.Scheduled?.Schedule?.Dates ??
          []
      ).length > 0
    )
      callForPopup()
  }
)
</script>
