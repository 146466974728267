<template>
  <label class="v-ui-checkbox">
    <input
      :id="uid"
      class="v-ui-checkbox__input"
      :aria-checked="checked"
      :aria-label="label.toString()"
      :disabled="disabled"
      :inert="disabled"
      type="checkbox"
      v-model="checked"
    />
    <svg
      class="v-ui-checkbox__check"
      width="22"
      height="22"
      viewBox="0 0 23 23"
    >
      <polyline points="20 6 9 17 4 12" />
    </svg>
    <span :class="`v-ui-checkbox__label ${labelClassName ?? ''}`">
      <slot>
        {{ label }}
      </slot>
    </span>
  </label>
</template>

<script setup lang="ts">
import type { VElement } from '@arora/common'

defineProps<VElement & { labelClassName?: string }>()

const checked = defineModel('checked', { default: false, type: Boolean })
const uid = useId()
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-ui-checkbox {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;

  &__label {
    margin-left: 12px;
    flex: 0 0 calc(100% - 36px);
    width: calc(100% - 36px);
  }

  &__input {
    position: absolute;
    right: 0;
    width: 0;
    height: 0;
    opacity: 0;

    &:checked {
      + {
        .v-ui-checkbox__check {
          border-color: variables.$SecondaryBackgroundColor;
          background: variables.$SecondaryBackgroundColor;
          stroke-dashoffset: 0;
        }
      }
    }
  }

  &__check {
    height: 24px;
    flex: 0 0 24px;
    border: 0.2rem solid variables.$Mono400;
    background: none;
    stroke: variables.$PrimaryColor;
    stroke-dasharray: 25;
    stroke-dashoffset: 25;
    stroke-linecap: round;
    stroke-width: 0.2rem;
    border-radius: 0.2rem;
    fill: none;
    transition:
      background 0.3s,
      stroke-dashoffset 0.4s;
  }
}
</style>
