<i18n>
ru:
  priorityCookingFor: Приоритетное приготовление за
  priorityDeliveryFor: Приоритетная доставка за
  priorityDescriptionDelivery: >-
    Выберите эту опцию и получите свои любимые блюда с
    максимальной скоростью
  priorityDescriptionSelfService: >-
    Выберите эту опцию и получите свои любимые блюда с
    максимальной скоростью
  priorityDescriptionIntroDelivery: Мы доставим Ваш заказ еще быстрее!
  priorityDescriptionIntroSelfService: Мы приготовим Ваш заказ еще быстрее!
ua:
  priorityCookingFor: Приоритетне приготування за
  priorityDeliveryFor: Приоритетна доставка за
  priorityDescriptionDelivery: >-
    Оберіть цей варіант і отримайте свої улюблені страви з максимальною
    швидкістю
  priorityDescriptionSelfService: >-
    Виберіть цей варіант і отримуйте улюблені страви з
    максимальною швидкістю
  priorityDescriptionIntroDelivery: Ми доставимо ваше замовлення ще швидше!
  priorityDescriptionIntroSelfService: Ми підготуємо Ваше замовлення ще швидше!
us:
  priorityCookingFor: Priority cooking for
  priorityDeliveryFor: Priority delivery for
  priorityDescriptionDelivery: >-
    Choose this option and get your favorite dishes as
    fast as possible
  priorityDescriptionSelfService: >-
    Choose this option and get your favorite meals delivered
    with maximum speed
  priorityDescriptionIntroDelivery: "We'll deliver your order even faster!"
  priorityDescriptionIntroSelfService: We will prepare your order even faster!
</i18n>

<template>
  <transition
    mode="out-in"
    name="slidedown"
  >
    <div
      v-if="
        (appConfig.RestaurantSettingsPreRun.PriorityServiceDeliveryEnabled &&
          clientStore.courierDelivery) ||
        (appConfig.RestaurantSettingsPreRun.PriorityServicePickupEnabled &&
          clientStore.selfService)
      "
      class="v-priority-collection__wrapper v-d-flex v-mb-sm"
    >
      <ui-checkbox
        :label="
          clientStore.courierDelivery
            ? translate('priorityService.priorityDeliveryFor')
            : translate('priorityService.priorityCookingFor')
        "
        v-model:checked="priorityService"
        label-class-name="v-d-flex"
      >
        <span
          class="v-mr-xxs"
          v-html="
            clientStore.courierDelivery
              ? translate('priorityService.priorityDeliveryFor')
              : translate('priorityService.priorityCookingFor')
          "
        />
        <common-currency
          v-if="clientStore.courierDelivery"
          class="v-mr-xxs"
          :amount="appConfig.RestaurantSettingsPreRun.PriorityServiceDeliveryPrice"
        />
        <common-currency
          v-else
          class="v-mr-xxs"
          :amount="appConfig.RestaurantSettingsPreRun.PriorityServicePickupPrice"
        />
        <common-popover class="v-priority-service__info v-pointer">
          <template #hoverable>
            <span>
              <ui-icon
                color="mono400"
                :size="16"
                disable-hover
                name="Inform"
              />
            </span>
          </template>
          <template #content>
            <div
              v-html="
                clientStore.courierDelivery
                  ? translate('priorityService.priorityDescriptionIntroDelivery')
                  : translate('priorityService.priorityDescriptionIntroSelfService')
              "
            />
            <div
              v-html="
                clientStore.courierDelivery
                  ? translate('priorityService.priorityDescriptionDelivery')
                  : translate('priorityService.priorityDescriptionSelfService')
              "
            />
          </template>
        </common-popover>
      </ui-checkbox>
    </div>
  </transition>
</template>

<script setup lang="ts">
const clientStore = useClientStore()

const { translate } = useI18nSanitized()

const appConfig = useAppConfig()
const priorityService = computed<boolean>({
  get: () => clientStore.ClientState?.data?.PriorityService?.Active ?? false,
  set: (value: boolean) => {
    clientStore.priorityService(value)
  }
})
</script>

<style lang="scss">
@use 'assets/mixins';

.v-priority-service {
  &__info {
    &--icon {
      width: 20px;
      height: 20px;
      display: inline-flex;
      opacity: 0.3;

      @include mixins.trans;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
</style>
