<i18n>
ru:
  cash: Наличными курьеру
  cashChange: 'Нужна сдача с:'
  card: Картой при получении
  emptyCart: Корзина пуста
  minSumNotReached: Не достигнута минимальная стоимость заказа
  newCardOnline: "Новой картой онлайн"
  online: Картой на сайте
  payMethod: Способ оплаты
  terminalDisabled: Не выбран адрес доставки/самовывоза
  tip: Чаевые
  qr: Системой быстрых платежей
ua:
  cash: Готівкою кур'єру
  cashChange: 'Потрібна решта з:'
  card: Оплата картою кур'єру
  emptyCart: Кошик пустує
  minSumNotReached: Не досягнута мінімальна вартість замовлення
  newCardOnline: "Новою карткою онлайн"
  online: Картою на сайті
  payMethod: Спосіб оплати
  terminalDisabled: Не вибрано адресу доставки/самовивозу
  tip: Чайові
  qr: Платежі за QR-кодом
us:
  cash: Payment in cash
  cashChange: 'Need change from:'
  card: Pay with card in-store
  emptyCart: Your cart is empty
  minSumNotReached: The minimum order sum has not been reached
  newCardOnline: "With a new card online"
  online: Online payment
  payMethod: Pay method
  terminalDisabled: No delivery/pickup address selected
  tip: Tip
  qr: Pay with QR code
</i18n>

<template>
  <div
    class="v-pay-method"
    :class="{
      disabled: terminalDisabled || notHaveItemsInCart || minSumNotReached
    }"
    :inert="terminalDisabled || notHaveItemsInCart || minSumNotReached"
  >
    <div
      class="v-payment-title"
      v-html="translate('payMethodPage.payMethod')"
    />

    <div
      v-if="terminalDisabled"
      class="v-payments-error"
      v-html="translate('payMethodPage.terminalDisabled')"
    />
    <div
      v-else-if="notHaveItemsInCart"
      class="v-payments-error"
      v-html="translate('payMethodPage.emptyCart')"
    />
    <div
      v-else-if="minSumNotReached"
      class="v-payments-error"
      v-html="translate('payMethodPage.minSumNotReached')"
    />
    <div
      v-else
      class="v-payments"
    >
      <common-skeleton
        v-if="loading"
        :height="70"
        :loaders="3"
        :loaders-in-row="1"
        :space-between="10"
      />
      <template v-else>
        <div
          class="v-payment"
          v-if="
            (clientStore.ClientState.data?.PaymentBindingState?.Bindings ?? [])
              .length > 0
          "
          :class="{ 'v-active': paymentType === -1 }"
          data-test-id="payment-type-3-preselected"
          @click="setBinding()"
        >
          <template v-if="selectedOnlinePaymentBinding">
            <div class="v-payment__icon">
              <icon-manual-payment-maestro
                v-if="
                  selectedOnlinePaymentBinding.PaySystemProvider ===
                    PaymentSystemProvider.MaestroUK ||
                  selectedOnlinePaymentBinding.PaySystemProvider ===
                    PaymentSystemProvider.Maestro
                "
              />
              <icon-manual-payment-visa
                v-else-if="
                  selectedOnlinePaymentBinding.PaySystemProvider ===
                  PaymentSystemProvider.Visa
                "
              />
              <icon-manual-payment-mastercard
                v-else-if="
                  selectedOnlinePaymentBinding.PaySystemProvider ===
                  PaymentSystemProvider.Mastercard
                "
              />
              <icon-manual-payment-mir
                v-else-if="
                  selectedOnlinePaymentBinding.PaySystemProvider ===
                  PaymentSystemProvider.MIR
                "
              />
              <icon-manual-payment-jcb
                v-else-if="
                  selectedOnlinePaymentBinding.PaySystemProvider ===
                  PaymentSystemProvider.JCB
                "
              />
            </div>
            <div
              class="v-payment__title"
              v-html="`∙∙∙∙ ${selectedOnlinePaymentBinding.LastPart}`"
            />
          </template>
          <div class="v-payment__edit">
            <ui-icon
              @click="callForBindingsPopup"
              color="mono400"
              :size="24"
              name="edit"
            />
          </div>
        </div>
        <div
          class="v-payment"
          :class="{ 'v-active': paymentType === payType.Type }"
          v-for="payType in terminalPayTypes"
          :key="`pay${payType}`"
          :data-test-id="`payment-type-${payType.Type}`"
          @click="selectPayType(payType.Type)"
        >
          <div class="v-payment__icon">
            <icon-old-cart-pay-card v-if="payType.Type === PayType.BankCard" />
            <icon-old-cart-pay-card-online-add
              v-else-if="
                payType.Type === PayType.Online &&
                (clientStore.ClientState.data?.PaymentBindingState?.Bindings ?? [])
                  .length > 0
              "
            />
            <icon-old-cart-pay-card-online
              v-else-if="payType.Type === PayType.Online"
            />
            <icon-old-cart-pay-cash v-else-if="payType.Type === PayType.Cash" />
            <icon-old-cart-quick-pay-system
              v-else-if="
                payType.Type === PayType.QR &&
                appConfig.VueSettingsPreRun.Currency === ISO.RussianFederation
              "
            />
          </div>
          <div
            v-if="payType.Type === PayType.Cash"
            class="v-payment__title"
            v-html="translate('payMethodPage.cash')"
          />
          <div
            v-else-if="payType.Type === PayType.BankCard"
            class="v-payment__title"
            v-html="translate('payMethodPage.card')"
          />
          <div
            v-else-if="payType.Type === PayType.Online"
            class="v-payment__title"
            v-html="
              (clientStore.ClientState.data?.PaymentBindingState?.Bindings ?? [])
                .length > 0
                ? translate('payMethodPage.newCardOnline')
                : translate('payMethodPage.online')
            "
          />
          <div
            v-else-if="payType.Type === PayType.QR"
            class="v-payment__title"
            v-html="translate('payMethodPage.qr')"
          />
          <div class="v-payment__other">
            <transition
              appear
              mode="out-in"
              name="fade"
            >
              <div v-if="payType.Type === PayType.Cash && isUserPayingCash">
                <div class="v-change-fields-wrapper">
                  <div
                    class="v-change-fields-left-part"
                    v-html="translate('payMethodPage.cashChange')"
                  />
                  <div
                    class="v-change-fields-center-part v-d-flex v-flex-column"
                    v-form-validator="{
                      Form: validationForm,
                      Value: change,
                      Required: appConfig.VueSettingsPreRun.ChangeRequired,
                      Validations: ['digits']
                    }"
                  >
                    <input
                      class="v-form-control"
                      :required="appConfig.VueSettingsPreRun.ChangeRequired"
                      data-test-id="cash-input"
                      maxlength="9"
                      v-model.number="change"
                    />
                  </div>
                  <div
                    class="v-change-fields-right-part"
                    v-html="restaurantStore.CurrencySymbol"
                  />
                </div>
              </div>
            </transition>
          </div>
        </div>
      </template>
    </div>
    <cart-entwash-receipt-send
      v-show="!terminalDisabled && !notHaveItemsInCart && !minSumNotReached"
    />
  </div>

  <div v-if="appConfig.VueSettingsPreRun.ShowGratuity">
    <div
      class="v-change-fields-wrapper v-tips"
      v-form-validator="{
        Form: validationForm,
        Value: gratuity,
        Required: appConfig.VueSettingsPreRun.GratuityRequired,
        Validations: ['digits']
      }"
      data-test-id="gratuity"
    >
      <div
        class="v-change-fields-left-part"
        v-html="translate('payMethodPage.tip')"
      />
      <div class="v-change-fields-center-part v-d-flex v-flex-column">
        <input
          class="v-form-control required text-right"
          :required="appConfig.VueSettingsPreRun.GratuityRequired"
          data-test-id="gratuity-input"
          maxlength="9"
          v-model="gratuity"
        />
      </div>

      <div
        class="v-change-fields-right-part"
        v-html="restaurantStore.CurrencySymbol"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OrderTypeSetting, PayTypeSort } from '~types/addressStore'
import type { PaymentBinding } from '~types/clientStore'

import {
  ISO,
  PaymentSystemProvider,
  useBankCard,
  useCommon,
  vFormValidator
} from '@arora/common'

import { Guid, OrderType, PaySortableType, PayType } from '~api/consts'

const { translate } = useI18nSanitized()

const restaurantStore = useRestaurantStore()
const clientStore = useClientStore()
const addressStore = useAddressStore()
const popupStore = usePopupStore()

const appConfig = useAppConfig()

const { getPaymentSystemProvider } = useBankCard()

const validationForm = 'cart'

const loaded = computed(
  () =>
    addressStore.Terminals?.data !== null && clientStore.ClientState?.data !== null
)

const selectedOnlinePaymentBinding = computed<
  (PaymentBinding & { PaySystemProvider: PaymentSystemProvider }) | null
>(() => {
  if (
    !clientStore.ClientState.data ||
    !clientStore.ClientState.data.PaymentBindingState ||
    (clientStore.ClientState.data.PaymentBindingState.Bindings ?? []).length === 0
  )
    return null

  const id = clientStore.ClientState.data.PaymentBindingState.CurrentBindingId

  let result = clientStore.ClientState.data.PaymentBindingState.Bindings.find(
    (binding) => binding.BindingId === id
  )

  if (!result) {
    result = clientStore.ClientState.data.PaymentBindingState.DefaultDisplay
  }

  const paySystemProvider = getPaymentSystemProvider(result.FirstPart)

  return { ...result, PaySystemProvider: paySystemProvider }
})

onMounted(async () => {
  if (import.meta.client) {
    if (loaded.value) {
      updateTerminalDeliverySetting()
      updateTerminalPayTypes()
      if (
        terminalPayTypes.value.length > 0 &&
        terminalPayTypes.value.every((pt) => pt.Type !== paymentType.value)
      )
        selectPayType(terminalPayTypes.value[0].Type)
      loading.value = false
    } else {
      await addressStore.initTerminals()
      await clientStore.initClientState()
    }
  }
})

const { minSumNotReached, terminal } = useAddressSelector()
const { debounce } = useCommon()

const changeLocal = ref<string | null>(null)
const gratuityLocal = ref<string | null>(null)
const loading = ref<boolean>(true)

const changeDefaultValue = appConfig.VueSettingsPreRun.ChangeRequired ? '' : '0'

const change = computed<string>({
  get: () => {
    return (
      changeLocal.value ??
      clientStore.ClientState?.data?.StateOrderData?.Change?.toString() ??
      changeDefaultValue
    )
  },
  set: (value) => {
    changeLocal.value = value
    debounce(() =>
      clientStore.updateOrderData({
        change: value?.toString() ?? changeDefaultValue
      })
    )
  }
})

const gratuity = computed<string>({
  get: () => {
    return (
      gratuityLocal.value ??
      (clientStore.ClientState?.data?.StateOrderData?.Gratuity ?? 0).toString()
    )
  },
  set: (value) => {
    gratuityLocal.value = value.toString()
    debounce(() =>
      clientStore.updateOrderData({
        gratuity: Number.parseInt(value ?? 0)
      })
    )
  }
})

function selectPayType(payType: PayType): void {
  clientStore.updateOrderData({
    bindingId: Guid.Empty,
    paymentType: payType < 0 ? PayType.Online : (payType ?? 0),
    refreshState: true
  })
}

function setBinding(): void {
  if (!selectedOnlinePaymentBinding.value) return

  clientStore.updateOrderData({
    bindingId: selectedOnlinePaymentBinding.value.BindingId,
    paymentType: PayType.Online,
    refreshState: true
  })
}

function callForBindingsPopup(): void {
  popupStore.openPopup({
    popupClosable: true,
    popupName: 'payMethodBindingsPopup'
  })
}

const paymentType = computed<number>(() => {
  if (!clientStore.ClientState.data) return 0

  const payType = clientStore.ClientState.data.StateOrderData?.PaymentType ?? 0

  if (
    !Guid.IsNullOrEmpty(
      clientStore.ClientState.data.PaymentBindingState?.CurrentBindingId
    ) &&
    payType === PayType.Online
  ) {
    return -1
  }

  return payType
})

const notHaveItemsInCart = computed<boolean>(() => {
  return (clientStore.ClientState.data?.Cart?.Content ?? []).length === 0
})

const isUserPayingCash = computed<boolean>(() => {
  return paymentType.value === PayType.Cash
})
const terminalDisabled = computed<boolean>(() => {
  return (
    !clientStore.selectedTerminalId ||
    Guid.IsNullOrEmpty(clientStore.selectedTerminalId)
  )
})
const terminalDeliverySetting = ref<OrderTypeSetting | null>(null)
const terminalPayTypes = ref<PayTypeSort[]>([])

function updateTerminalDeliverySetting(): void {
  if (
    !terminal.value ||
    clientStore.ClientState?.data?.OrderType === OrderType.Default
  ) {
    terminalDeliverySetting.value = null

    return
  }

  switch (clientStore.ClientState?.data?.OrderType) {
    case OrderType.CourierDelivery: {
      terminalDeliverySetting.value = terminal.value.DeliverySetting
      break
    }
    case OrderType.NoShipment: {
      terminalDeliverySetting.value = terminal.value.SelfServiceSetting
      break
    }
    case OrderType.InHall: {
      terminalDeliverySetting.value = terminal.value.InHallSetting
      break
    }
  }
}

function updateTerminalPayTypes(): void {
  if (!clientStore.ClientState.data) return

  const terminalPayTypeSort: PayTypeSort[] = []
  const availablePaymentTypes = clientStore.ClientState.data.AvailablePaymentTypes

  if (!availablePaymentTypes) return

  const isAllPaymentTypesWeightNull = computed(() =>
    availablePaymentTypes.every(
      (payTypeSort: PayTypeSort) => payTypeSort.SortWeight === 0
    )
  )

  for (const payTypeSort of availablePaymentTypes) {
    if (payTypeSort.Type === PaySortableType.Cash) {
      terminalPayTypeSort.push({
        SortWeight: isAllPaymentTypesWeightNull.value ? 0 : payTypeSort.SortWeight,
        Type: PayType.Cash
      })
    }
    if (payTypeSort.Type === PaySortableType.Card) {
      terminalPayTypeSort.push({
        SortWeight: isAllPaymentTypesWeightNull.value ? 1 : payTypeSort.SortWeight,
        Type: PayType.BankCard
      })
    }
    if (payTypeSort.Type === PaySortableType.Online) {
      terminalPayTypeSort.push({
        SortWeight: isAllPaymentTypesWeightNull.value ? 2 : payTypeSort.SortWeight,
        Type: PayType.Online
      })
    }
    if (payTypeSort.Type === PaySortableType.QR) {
      terminalPayTypeSort.push({
        SortWeight: isAllPaymentTypesWeightNull.value ? 3 : payTypeSort.SortWeight,
        Type: PayType.QR
      })
    }
  }

  terminalPayTypes.value = terminalPayTypeSort
    .sort((a, b) => {
      return a.SortWeight >= b.SortWeight ? 1 : -1
    })
    .filter((payTypeSort) => {
      switch (payTypeSort.Type) {
        case PayType.Cash: {
          return terminalDeliverySetting.value?.CashPayEnabled
        }
        case PayType.BankCard: {
          return terminalDeliverySetting.value?.BankCardPayEnabled
        }
        case PayType.Online: {
          return terminalDeliverySetting.value?.OnlinePayEnabled
        }
        case PayType.QR: {
          return terminalDeliverySetting.value?.QRPayEnabled
        }
        default: {
          return false
        }
      }
    })
}

watch(
  () => loaded.value,
  (newState: boolean) => {
    if (newState) {
      updateTerminalDeliverySetting()
      updateTerminalPayTypes()
      loading.value = false
    }
  }
)

watch(
  () => clientStore.ClientState.data?.Version ?? null,
  async (newContent: number | null, oldContent: number | null) => {
    if (oldContent === null) return // after initialization oldContent is null
    if (newContent === null) return // in case of client state update error

    if (oldContent !== newContent) {
      loading.value = true
      updateTerminalDeliverySetting()
      updateTerminalPayTypes()

      if (
        terminalPayTypes.value.length > 0 &&
        paymentType.value >= 0 &&
        terminalPayTypes.value.every((pt) => pt.Type !== paymentType.value)
      ) {
        selectPayType(terminalPayTypes.value[0].Type)
      }

      await nextTick()
      loading.value = false
    }
  }
)
</script>

<style lang="scss">
@use 'assets/mixins';
@use 'assets/variables';

.v-payments-error {
  margin-bottom: 0.9rem;
}

.v-payment {
  display: grid;
  background: variables.$BodyElementsBackground;
  grid-template-columns: 35px auto 20px;
  grid-template-rows: 32px auto;
  gap: 0 12px;
  grid-auto-flow: row;
  grid-template-areas:
    'icon title edit'
    'other other other';

  cursor: pointer;
  border: 1px solid variables.$BorderColorLight;
  border-radius: variables.$BorderRadiusButton;
  padding: 16px;
  margin-bottom: 4px;
  @include mixins.trans;

  &:hover,
  &.v-active {
    border: 1px solid variables.$PrimaryBackgroundColor;

    .v-payment__icon {
      color: variables.$PrimaryBackgroundColor;
    }

    .quick-pay-system {
      color: variables.$PrimaryBackgroundColor;
      stroke: variables.$PrimaryBackgroundColor;
      @include mixins.trans;
    }
  }

  &__icon {
    grid-area: icon;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 32px;
      width: auto;
    }
  }

  &__title {
    grid-area: title;
    display: flex;
    align-items: center;
  }

  &__edit {
    grid-area: edit;
  }

  &__other {
    grid-area: other;
  }
}

.v-change-fields-wrapper {
  display: flex;
  margin-top: 15px;

  &.v-tips {
    margin-bottom: 1.1rem;

    .v-change-fields-center-part {
      display: block;
      width: 100%;
    }
  }
}

.v-change-fields-left-part {
  display: flex;
  background: variables.$FormBackground;
  color: variables.$BodyTextColor;
  border: variables.$BorderWidth solid variables.$BorderColor;
  border-radius: variables.$BorderRadius 0 0 variables.$BorderRadius;
  align-items: center;
  padding: 1rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  pointer-events: none;
}

.v-change-fields-center-part {
  display: flex;
  border: variables.$BorderWidth solid variables.$BorderColor;
  border-radius: 0;
  align-items: center;
  text-align: center;

  input {
    padding: 1rem 0;
    text-align: right;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.v-change-fields-center-part input {
  border: none;
  border-radius: 0;
  background: variables.$FormBackground;
  color: variables.$BodyTextColor;
  padding-right: 15px;
  width: 100%;
  height: 100%;
}

.v-change-fields-right-part {
  display: flex;
  background: variables.$FormBackground;
  color: variables.$BodyTextColor;
  border: variables.$BorderWidth solid variables.$BorderColor;
  border-radius: 0 variables.$BorderRadius variables.$BorderRadius 0;
  align-items: center;
  padding: 0.375rem 0.75rem;
  text-align: center;
  white-space: nowrap;
}

.v-payment-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 20px;
}
</style>
