<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="21"
    viewBox="0 0 29 21"
    fill="none"
  >
    <path
      d="M26.5621 12.8957V3.64348C26.5621 2.18353 25.4177 1 24.0059 1H3.55621C2.14446 1 1 2.18353 1 3.64348V16.8609C1 18.3208 2.14446 19.5043 3.55621 19.5043H16.5393H21.1302"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.43799 7.6084L25.2866 7.6084"
      stroke="currentColor"
      stroke-width="3"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M28 17.9346H24.0059"
      stroke="currentColor"
      stroke-linecap="round"
    />
    <path
      d="M28 17.9346H24.0059"
      stroke="currentColor"
      stroke-opacity="0.2"
      stroke-linecap="round"
    />
    <path
      d="M28 17.9346H24.0059"
      stroke="currentColor"
      stroke-opacity="0.2"
      stroke-linecap="round"
    />
    <path
      d="M26.0029 20L26.0029 15.8696"
      stroke="currentColor"
      stroke-linecap="round"
    />
    <path
      d="M26.0029 20L26.0029 15.8696"
      stroke="currentColor"
      stroke-opacity="0.2"
      stroke-linecap="round"
    />
    <path
      d="M26.0029 20L26.0029 15.8696"
      stroke="currentColor"
      stroke-opacity="0.2"
      stroke-linecap="round"
    />
  </svg>
</template>
